import React from "react";
function Footer() {
  return (
    <section class="section-footer">
      <div class="section-footer-component">
        <div class="footer-layout">
          <div class="footer-layout-flex">
            <div>
              <img src="./asset/footer.png" alt="" />
              <div class="sosyal-footer">
                <a href="">+44 20 8133 0324 </a>
                <a href=""> info@getproperties.com</a>
              </div>
              <a href="#">
                <img src="./asset/sosyal.png" alt="" />
              </a>
            </div>
            <div class="footer-links-flex">
              <div class="footer-link-flex">
                <p>Properties</p>
                <a href="#">Wise Review</a>
                <a href="#"> Open Wise Account</a>
                <a href="#"> N26</a>
                <a href="#">E-Residency Countries</a>
                <a href="#"> Wise Review</a>
              </div>
              <div class="footer-link-flex">
                <p>Properties</p>
                <a href="#">Wise Review</a>
                <a href="#"> Open Wise Account</a>
                <a href="#"> N26</a>
                <a href="#">E-Residency Countries</a>
                <a href="#"> Wise Review</a>
              </div>
              <div class="footer-link-flex">
                <p>Properties</p>
                <a href="#">Wise Review</a>
                <a href="#"> Open Wise Account</a>
                <a href="#"> N26</a>
                <a href="#">E-Residency Countries</a>
                <a href="#"> Wise Review</a>
              </div>
              <div class="footer-link-flex">
                <p>Properties</p>
                <a href="#">Wise Review</a>
                <a href="#"> Open Wise Account</a>
                <a href="#"> N26</a>
                <a href="#">E-Residency Countries</a>
                <a href="#"> Wise Review</a>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-2">
          <div class="footer-2-flex">
            <p>LONDON</p>
            <a class="link" href="#">
              1 Fore Street Avenue,
            </a>
            <a class="link" href="#">
              EC2Y 9DT
            </a>
            <a class="link" href="#">
              London / United Kingdom
            </a>
            <a class="link" href="#">
              +44 20 8133 0324
            </a>
            <a class="buttonLarge" href="#">
              Show in map
            </a>
          </div>
          <div class="footer-2-flex">
            <p>LONDON</p>
            <a class="link" href="#">
              1 Fore Street Avenue,
            </a>
            <a class="link" href="#">
              EC2Y 9DT
            </a>
            <a class="link" href="#">
              London / United Kingdom
            </a>
            <a class="link" href="#">
              +44 20 8133 0324
            </a>
            <a class="buttonLarge" href="#">
              Show in map
            </a>
          </div>
          <div class="footer-2-flex">
            <p>LONDON</p>
            <a class="link" href="#">
              1 Fore Street Avenue,
            </a>
            <a class="link" href="#">
              EC2Y 9DT
            </a>
            <a class="link" href="#">
              London / United Kingdom
            </a>
            <a class="link" href="#">
              +44 20 8133 0324
            </a>
            <a class="buttonLarge" href="#">
              Show in map
            </a>
          </div>
          <div class="footer-2-flex">
            <p>LONDON</p>
            <a class="link" href="#">
              1 Fore Street Avenue,
            </a>
            <a class="link" href="#">
              EC2Y 9DT
            </a>
            <a class="link" href="#">
              London / United Kingdom
            </a>
            <a class="link" href="#">
              +44 20 8133 0324
            </a>
            <a class="buttonLarge" href="#">
              Show in map
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
