import React from "react";
function Header() {
  return (
    <div>
      <section class="container-L">
        <div class="row justi">
          <nav>
            <ul class="row">
              <li>
                <a href="#">Properties</a>
              </li>
              <li>
                <a href="#">Articles</a>
              </li>
              <li>
                <a href="#">Calculator</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
          <div class="Logo">
            <img src="asset/Component 6.png" alt="logo" />
          </div>
          <div class="row inputContainer">
            <input type="text" placeholder="Search" class="ınputSearch" />
            <img src="./asset/Frame 61681.png" alt="search" />
          </div>
          <a href="#" class="buttonIn">
            Sey Hello!
          </a>
          <div class="row justi en">
            <p>En</p>
            <img src="./asset/Vector 35.png" alt="vektor" />
          </div>
        </div>
      </section>
      <section class="container-M">
        <div class="innerContext">
          <div class="topLeft">
            <h1>We help you find</h1>
            <h2>Property</h2>
            <div class="innerTextBox">
              <div class="innertext">
                <p>Portugal Property Seach</p>
              </div>
              <div class="row innerInput">
                <div class="search">
                  <img src="./asset/Frame 61681.png" alt="vek" />
                  <input type="text" placeholder="Search in Portugal" />
                </div>
                <div class="row type">
                  <div>
                    <p>Type</p>
                    <span>Apartment</span>
                  </div>
                  <img src="./asset/Vector 35.png" alt="" />
                </div>
                <a href="#" class="buttonBorder">
                  Search
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
