import React from "react";

function Why() {
  return (
    <section class="section-why">
      <div class="row">
        <h1 class="why-title"> Why GetProperties?</h1>
        <div class="picks-node"></div>
      </div>
      <div class="why-paragrafy">
        <p>
          We help our clients make a property investment that meets their
          lifestyle expectations in Portugal, Greece, Spain, and Turkey. We
          offer professional advisory services for property selection and
          acquisition, management, and rental services. With a unique approach
          that includes market understanding and location advisory, we focus on
          delivering a tailored-made and efficient service for our international
          clients.
        </p>
        <p>
          With local offices in Lisbon, Athens, Istanbul, and London and various
          international associations, Get Properties will be on your side
          throughout the whole property investment journey.
        </p>
      </div>
      <div class="why-icon">
        <div class="why-line-2"></div>
        <div class="why-line-1"></div>
        <div class="why-image">
          <div>
            <img src="./asset/House.png" alt="" />
            <p>Schedule an Appointment with</p>
          </div>
          <div>
            <img src="./asset/Frame 62184.png" alt="" />
            <p>Search and Choose the Right Property</p>
          </div>
          <div>
            <img src="./asset/Frame 62187.png" alt="" />
            <p>Complete the Property Purchase</p>
          </div>
        </div>
      </div>
      <div class="why-support">
        <img class="why-imageConteiner" src="./asset/Group 61459.jpg" alt="" />
        <div class="why-absolute">
          <div class="why-vector">
            <img src="/asset/Vector 50.png" alt="" />
            <img src="./asset/table.png" alt="" />
          </div>
          <div class="why-content">
            <h1>Get Concierge</h1>
            <p class="why-content-p1">
              Get Support for Property Management and Rentals
            </p>
            <p class="why-content-p2">
              Learn how Concierge can help sell your home faster and for a
              higher price.
            </p>
          </div>
          <a href="#" class="buttonIn">
            Get Concierge
          </a>
        </div>
      </div>
    </section>
  );
}

export default Why;
