import React from "react";

function OurPicks() {
  return (
    <section class="section-picks">
      <div class="picks-title">
        <div class="row-end">
          <p class="picks-title-1">Our top Picks</p>
          <div class="picks-node"></div>
        </div>
        <div class="row">
          <p class="picks-title-2">See All Properties</p>
          <img src="./asset/Vector 36.png" alt="title" />
        </div>
      </div>
      <p class="articles-paragrafy">
        Find the best article opportunities find the best article opportunities
      </p>
      <div class="picks-content">
        <div class="row-photo">
          <div class="articles-card">
            <img src="./asset/Rectangle 577.png" alt="" />
            <div class="articles-content">
              <div class="articles-date">
                <p class="articles-date-p1">Article Category</p>
                <p class="articles-date-p2">10 Ocak 2022</p>
              </div>
              <p class="articles-content-p">These ultra sleek</p>
              <span>
                These ultra sleek and contemporary new villas are situated on a
                seafront
              </span>
              <div class="articles-textinner">
                <a>Read More</a>
              </div>
            </div>
          </div>

          <div class="articles-card">
            <img src="./asset/Rectangle 577.png" alt="" />
            <div class="articles-content">
              <div class="articles-date">
                <p class="articles-date-p1">Article Category</p>
                <p class="articles-date-p2">10 Ocak 2022</p>
              </div>
              <p class="articles-content-p">These ultra sleek</p>
              <span>
                These ultra sleek and contemporary new villas are situated on a
                seafront
              </span>
              <div class="articles-textinner">
                <a>Read More</a>
              </div>
            </div>
          </div>
          <div class="articles-card">
            <img src="./asset/villa.png" alt="" />
            <div class="articles-content">
              <div class="articles-date">
                <p class="articles-date-p1">Article Category</p>
                <p class="articles-date-p2">10 Ocak 2022</p>
              </div>
              <p class="articles-content-p">These ultra sleek</p>
              <span>
                These ultra sleek and contemporary new villas are situated on a
                seafront
              </span>
              <div class="articles-textinner">
                <a>Read More</a>
              </div>
            </div>
          </div>

          <div class="articles-card">
            <img src="./asset/masa.png" alt="" />
            <div class="articles-content">
              <div class="articles-date">
                <p class="articles-date-p1">Article Category</p>
                <p class="articles-date-p2">10 Ocak 2022</p>
              </div>
              <p class="articles-content-p">These ultra sleek</p>
              <span>
                These ultra sleek and contemporary new villas are situated on a
                seafront
              </span>
              <div class="articles-textinner">
                <a>Read More</a>
              </div>
            </div>
          </div>
          <a class="prev-button picks-1">
            <img src="./asset/Vector 36.png" alt="prev" />
          </a>
          <a class="next-button picks-2">
            <img src="./asset/Vector 36.png" alt="prev" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default OurPicks;
