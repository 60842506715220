import React from "react";

function Picks() {
  return (
    <section class="section-picks">
      <div class="picks-title">
        <div class="row-end">
          <p class="picks-title-1">Our top Picks</p>
          <div class="picks-node"></div>
        </div>
        <div class="row">
          <p class="picks-title-2">See All Properties</p>
          <img src="./asset/Vector 36.png" alt="title" />
        </div>
      </div>
      <div class="picks-content">
        <div class="row-photo">
          <div class="picks-card">
            <img src="./asset/Rectangle 577.png" alt="" />
            <div class="picks-inner">
              <p>Barcelona, Spain</p>
              <p>266 Libardade</p>
              <p>Starting from - €1,450,000</p>
            </div>
            <div class="picks-textinner">
              <p>
                These ultra sleek and contemporary new villas are situated on a
                seafront cliff top with breath-taking views over the ocean and
                the natural landscape surrounding them.{" "}
              </p>
              <a>Get in</a>
            </div>
          </div>

          <div class="picks-card">
            <img src="./asset/Rectangle 576.png" alt="" />
            <div class="picks-inner">
              <p>Barcelona, Spain</p>
              <p>266 Libardade</p>
              <p>Starting from - €1,450,000</p>
            </div>
            <div class="picks-textinner active">
              <p>
                These ultra sleek and contemporary new villas are situated on a
                seafront cliff top with breath-taking views over the ocean and
                the natural landscape surrounding them.{" "}
              </p>
              <a>Get in</a>
            </div>
          </div>

          <div class="picks-card">
            <img src="./asset/Rectangle 577.png" alt="" />
            <div class="picks-inner">
              <p>Barcelona, Spain</p>
              <p>266 Libardade</p>
              <p>Starting from - €1,450,000</p>
            </div>
            <div class="picks-textinner">
              <p>
                These ultra sleek and contemporary new villas are situated on a
                seafront cliff top with breath-taking views over the ocean and
                the natural landscape surrounding them.{" "}
              </p>
              <a>Get in</a>
            </div>
          </div>
          <a class="prev-button picks-1">
            <img src="./asset/Vector 36.png" alt="prev" />
          </a>
          <a class="next-button picks-2">
            <img src="./asset/Vector 36.png" alt="prev" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Picks;
