import React from "react";

function NewHome() {
  return (
    <section class="new-home">
      <img src="./asset/Vector.png" alt="home" />
      <div>
        <h1>
          Your New Home
          <br />
          in Lizbon
        </h1>
        <p>Find the best real estate opportunities</p>
      </div>
      <a class="prev-button new-1">
        <img src="./asset/Vector 36.png" alt="prev" />
      </a>
      <a class="next-button new-2">
        <img src="./asset/Vector 36.png" alt="next" />
      </a>
    </section>
  );
}

export default NewHome;
