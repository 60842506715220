import React from "react";

function Touch() {
  return (
    <section class="section-touch">
      <div class="section-Conteiner">
        <div class="row">
          <h1 class="why-title"> Why GetProperties?</h1>
          <div class="picks-node"></div>
        </div>

        <div class="touch-ınputConteiner">
          <div class="touch-ınput">
            <div class="touch-ınput-card">
              <label for="fname">Name</label>
              <input
                class="card-ınput"
                type="text"
                id="fname"
                name="fname"
                placeholder="Emre"
              />
            </div>
            <div class="touch-ınput-card">
              <label for="Surname">Surname</label>
              <input
                class="card-ınput"
                type="text"
                id="Surname"
                name="Surname"
                placeholder="Usul"
              />
            </div>
            <div class="touch-ınput-card">
              <label for="email">E-Mail</label>
              <input
                class="card-ınput"
                type="text"
                id="email"
                name="email"
                placeholder="emreeusul@gmail.com"
              />
            </div>
            <div class="touch-ınput-card">
              <label for="phone">Phone Number</label>
              <input
                class="card-ınput"
                type="text"
                id="phone"
                name="phone"
                placeholder="+905051165625"
              />
            </div>
          </div>
          <div class="touch-ınput">
            <div class="touch-ınput-card">
              <label for="fname">Country</label>
              <input
                class="card-ınput"
                type="text"
                id="fname"
                name="fname"
                placeholder="Turkey"
              />
            </div>
            <div class="touch-ınput-card">
              <label for="fname">Select Area of Interest</label>
              <input
                class="card-ınput"
                type="text"
                id="fname"
                name="fname"
                placeholder="Select Area of Interest"
              />
            </div>
            <div class="touch-ınput-card">
              <label for="fname">Select Area of Interest</label>
              <textarea
                id="w3review"
                name="w3review"
                placeholder="Enter your message here"
              ></textarea>
            </div>
            <div class="touch-button">
              <div class="touch-button-radio">
                <div class="buttonCard">
                  <input type="radio" id="fname" name="fname" />
                </div>
                <label for="fname">I’m not a robot</label>
                <img src="./asset/geridönüs.png" alt="" />
              </div>
              <a href="#" class="buttonIn">
                Send
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="Fetured-title">
        <div class="row">
          <h1 class="why-title"> Why GetProperties?</h1>
          <div class="picks-node"></div>
        </div>
        <div class="Fetured-image">
          <div>
            <img src="./asset/FT.png" alt="" />
          </div>
          <div>
            <img src="./asset/styleText.png" alt="" />
          </div>
          <div>
            <img src="./asset/Go.png" alt="" />
          </div>
        </div>
      </div>
      <div class="letus">
        <p>
          Let us accompany your overseas <br />
          property investment process
        </p>
        <div class="card">
          <input
            class="card-ınput"
            type="text"
            id="fname"
            name="fname"
            placeholder="E-mail"
          />
          <div class="letus-flex">
            <div class="row letus-flex-radio">
              <div class="letus-radio">
                <input type="radio" id="fname" name="fname" />
              </div>
              <label for="fname">I’m not a robot</label>
            </div>
            <div class="articles-textinner">
              <a>
                Read More
                <img src="./asset/Vector 36.png" alt="prev" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Touch;
