import Touch from "./components/Touch";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NewHome from "./components/NewHome";
import Picks from "./components/Picks";
import Why from "./components/Why";
import OurPicks from "./components/OurPicks";

function App() {
  return (
    <div className="App">
      <Header />
      <Picks />
      <NewHome />
      <Why />
      <OurPicks />
      <Touch />
      <Footer />
    </div>
  );
}

export default App;
